@import '_colors.sass'

button.button:active, button.button:focus
	outline: none
	border: none
	box-shadow: none

.button
	background-color: $createButton
	color: white
	text-transform: uppercase
	font-weight: bold
	text-decoration: none
	font-size: 14px
	border-radius: 3px
	cursor: pointer
	border: none
	padding: 5px

	&:hover
		background-color: lighten($createButton, $hoverLightenPercentage)
		color: white
		text-decoration: none

	&.deletebutton
		background-color: $deleteButton

		&:hover
			background-color: lighten($deleteButton, $hoverLightenPercentage)

	&.backbutton, &.cancel
		background-color: $backButton

		&:hover
			background-color: lighten($backButton, $hoverLightenPercentage)

	&.editbutton
		background-color: $editButton

		&:hover
			background-color: lighten($editButton, $hoverLightenPercentage)

	&.production-button
		background-color: #4984f1
		&:hover
			background-color: lighten(#4984f1, $hoverLightenPercentage)

.iconbutton i
	font-size: 22px
	margin-right: 10px


.table-controls-wrapper .button
	border: none
	border-radius: 50%
	padding: 11px
	font-size: 20px
	font-weight: normal
	margin-left: 5px
	height: 40px
	width: 40px
	text-align: center
	display: flex
	align-items: center
	justify-content: center
	float: left

.card-footer .button
	margin-right: 5px
