.weed-badge
	@apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-weed-button-primary text-weed-text-secondary
	&.danger
		@apply bg-weed-button-delete text-weed-text-secondary

.mdc-checkbox__background
	width: 1.5rem !important
	height: 1.5rem !important
	border: 0.125rem solid var(--color-mat-form-selected) !important
	border-radius: .25rem !important
	top: 50% !important
	left: 50% !important
	transform: translate(-50%, -50%) !important
	-ms-transform: translate(-50%, -50%) !important

.mat-mdc-checkbox-ripple, .mdc-checkbox__ripple
	display: none !important

.mdc-checkbox__native-control:checked~.mdc-checkbox__background, .mdc-checkbox__native-control:indeterminate~.mdc-checkbox__background, .mdc-checkbox__native-control[data-indeterminate=true]~.mdc-checkbox__background
	background-color: var(--color-mat-form-selected) !important

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark
	color: var(--color-mat-form-check) !important

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after
	border-bottom-color: var(--color-mat-form-selected) !important

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow
	color: var(--color-mat-form-selected) !important

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text
	color: var(--color-mat-form-selected) !important
	font-weight: bold !important

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after, .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after
	color: var(--color-mat-form-selected) !important

// Radio Buttons
.mdc-radio__background
	border-radius: 0.25rem !important
	width: 1.5rem !important
	height: 1.5rem !important
	border: 0.125rem solid var(--color-mat-form-selected) !important
	top: 50% !important
	left: 50% !important
	transform: translate(-50%, -50%) !important
	-ms-transform: translate(-50%, -50%) !important

.mdc-radio__outer-circle
	border: none !important
	transition: none !important

.mdc-radio__inner-circle
	top: -12px !important
	left: -12px !important
	border-width: 1.4rem !important
	border-radius: 0.25rem !important
	position: relative !important

.mdc-radio__inner-circle::before
	content: "\f00c"
	font-family: "Font Awesome 6 Free"
	font-weight: 900
	color: var(--color-mat-form-check)
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	font-size: 2rem
	display: none

.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle::before
	display: block


.mat-mdc-radio-button .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle
	border-color: var(--color-mat-form-selected) !important

.mdc-radio__native-control:checked+.mdc-radio__background .mdc-radio__inner-circle, .mdc-radio__native-control:disabled+.mdc-radio__background .mdc-radio__inner-circle
	border-color: var(--color-mat-form-selected) !important

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle
	border-color: var(--color-mat-form-selected) !important

.mat-mdc-paginator-container
	min-height: unset !important

mat-radio-button
	&.score-green
		.mdc-form-field>label
			font-weight: 700 !important
			color: var(--color-score-green) !important

	&.score-yellow
		.mdc-form-field>label
			font-weight: 700 !important
			color: var(--color-score-yellow) !important

	&.score-red
		.mdc-form-field>label
			font-weight: 700 !important
			color: var(--color-score-red) !important

input, select, textarea
	@apply text-weed-text w-full px-4 py-2 bg-weed-input rounded-lg

input, select, textarea
	&.filter-form
		@apply w-60 h-12


.mat-mdc-text-field-wrapper
	border-radius: 0.5rem !important

ali-input-input, ali-input-select, ali-input-textarea
	@apply w-full

.mdc-text-field--filled:not(.mdc-text-field--disabled)
	background-color: var(--color-input-inner-card) !important

input, select, textarea
	&:disabled
		opacity: 50%

.form-field-date-range
	@apply relative flex
	background-color: var(--color-input) !important
	border-radius: 0.5rem
	.mat-date-range-input
		.mat-date-range-input-container
			height: 100%
			padding-left: 1rem
