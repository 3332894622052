.ali-card
	@apply w-full bg-weed-card-background rounded-lg overflow-hidden h-full
	&.half
		@apply w-1/2
	&.small
		@apply h-1/2
	&.medium
		@apply h-2/3
	&.extra-small
		@apply h-36
	.ali-card-header
		@apply flex justify-between items-center px-6 py-2 bg-weed-card-header min-h-16
		&.two-rows
			@apply flex-col gap-4
			.header-row
				@apply flex justify-between w-full
		&.score-red
			@apply bg-weed-score-red text-weed-text-secondary
		&.score-green
			@apply bg-weed-score-green
		&.score-yellow
			@apply bg-weed-score-yellow
		.header-text
			@apply text-xl text-weed-card-header-text

	.ali-card-content
		@apply h-full p-4 overflow-auto text-weed-text
		height: calc(100dvh - 11rem)
		&.without-footer
			height: calc(100dvh - 5rem)

		&.small
			max-height: calc(50dvh - 10.5rem)
			&.without-footer
				max-height: calc(50dvh - 13rem)

		&.medium
			max-height: calc(66dvh - 9rem)
			&.without-footer
				max-height: calc(66dvh - 11rem)
		&.with-stepper
			max-height: calc(100dvh - 19rem)

		&.with-filter
			height: calc(100dvh - 15rem)

	.ali-card-footer
		@apply p-4 border-t border-weed-text min-h-10 flex justify-between
