.modal-component
	@apply flex flex-col gap-4 h-full bg-weed-card-background
	min-width: 36.25rem
	max-height: 65dvh

	.header-content
		@apply w-full bg-weed-card-header p-4 flex justify-between text-weed-card-header-text

		.modal-close-button
			@apply text-xl h-10 w-10 relative

	.modal-body-content
		@apply overflow-y-auto w-full h-full
		min-width: 36.25rem
		min-height: 25rem
		max-height: 50dvh

	.modal-body-container
		@apply p-4 h-full overflow-y-auto w-full



	.modal-button-container
		@apply flex justify-between p-4 border-t border-gray-300

