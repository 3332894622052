button
	&.btn-rounded
		@apply h-10 w-10 bg-weed-button-primary  rounded-full relative text-weed-text-secondary hover_bg-weed-button-primary-hover
		&.big
			@apply h-12 w-12

		&.delete
			@apply bg-weed-button-delete hover_bg-weed-button-delete-hover

		&.edit
			@apply bg-weed-button-secondary hover_bg-weed-button-secondary-hover
		&.redo
			@apply bg-weed-button-tertiary hover_bg-weed-button-tertiary-hover

		&:disabled
			@apply opacity-75 cursor-not-allowed

		&.navigation
			@apply bg-transparent

	&.btn-primary
		@apply rounded-md py-2 px-4 bg-weed-button-primary text-weed-text-secondary hover_bg-weed-button-primary-hover uppercase text-sm font-bold

	&.btn-secondary
		@apply rounded-md py-2 px-4 bg-weed-button-secondary text-weed-text-secondary hover_bg-weed-button-secondary-hover uppercase text-sm font-bold

	&.btn-tertiary
		@apply rounded-md py-2 px-4 bg-weed-button-tertiary text-weed-text-secondary hover_bg-weed-button-tertiary-hover uppercase text-sm font-bold

	&.btn-delete
		@apply rounded-md py-2 px-4 bg-weed-button-delete text-weed-text-secondary hover_bg-weed-button-delete-hover uppercase text-sm font-bold
