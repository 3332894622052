h1
	@apply text-3xl
h2
	@apply text-2xl
h3
	@apply text-xl
h4
	@apply text-lg
h5
	@apply text-base
h6
	@apply text-sm
hr
	@apply py-2
