@import 'colors'

.order-status-icon .icon,
.webshop-icon i
	border: none
	border-radius: 50%
	font-size: 20px
	font-weight: normal
	margin-left: 5px
	height: 40px
	width: 40px
	text-align: center
	display: block
	color: white


.order-status-icon .icon
	padding: 6px 11px 11px

	&.open
		background-color: $deleteButton

	&.confirmed
		background-color: $editButton

	&.booked
		background-color: $createButton

.webshop-icon i
	font-size: 36px

	&.fa-times-circle
		color: $deleteButton

	&.fa-check-circle
		color: $createButton
