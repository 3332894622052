.table-container
	@apply overflow-y-auto
	height: calc(100dvh - 13rem)
	&.small
		height: calc(100dvh - 44.5rem)

	&.half-table
		height: calc(50dvh - 13rem)

	&.with-filter-head
		height: calc(100dvh - 17rem)

.mat-mdc-table tbody, .mat-mdc-table tfoot, .mat-mdc-table thead, .mat-mdc-cell, .mat-mdc-footer-cell, .mat-mdc-header-row, .mat-mdc-row, .mat-mdc-footer-row, .mat-mdc-table .mat-mdc-header-cell
	&.selected
		background-color: #c1c0c0 !important


.mat-mdc-cell
	&.no-buffer
		@apply text-red-500 font-bold
