/* You can add global styles to this file, and also import other style files */
/**** tailwind ****/
@import "tailwindcss/base"
@import "tailwindcss/components"
@import "tailwindcss/utilities"

@import './sass/_buttons.sass'
@import './sass/_article-status-icon.sass'

/**** custom styles ****/
@import "styles/color/color"
@import "styles/navigation"
@import "styles/text"
@import "styles/icon"
@import "styles/button"
@import "styles/mat-menu"
@import "styles/forms"
@import "styles/font"
@import "styles/modal/modal"
@import "styles/page/page"
@import "styles/status-bar/status-bar"
@import "styles/card/card"
@import "styles/table/table"
@import "poppins-font"
@import "styles/main/main"


html, body
	overflow: hidden
	overscroll-behavior-x: none

body
	margin: 0
	padding: 0
	font-family: Poppins, sans-serif
	@apply text-weed-text bg-weed-background

input[type=number]::-webkit-inner-spin-button
	-webkit-appearance: none

input:focus
	outline: 0
	box-shadow: none

table
	width: 100%

.table-controls-wrapper
	white-space: nowrap

.card
	//margin-bottom: 20px

mat-form-field
	width: 100%

table mat-form-field
	width: 50%

	.mat-form-field-infix
		width: auto

textarea:focus
	background-color: rgba(0, 0, 0, .04)


a > i
	margin-left: 5px

.content
	margin-bottom: 10px

	strong
		display: block

table .content
	margin-bottom: 0

.card-header
	h1, h2, h3, h4, h5, h6
		margin-bottom: 0

.error
	color: red

span.error
	font-size: 12px


.inactive
	opacity: 0.2
	pointer-events: none
	user-select: none
	cursor: not-allowed

// For Datepicker - modal Overlay has higher z-index
body.modal-open .cdk-overlay-container
	z-index: 3000


::-webkit-scrollbar
	width: 4px


::-webkit-scrollbar-thumb
	background-color: gray
	border-radius: 1rem


.rounded-button
	position: relative
	cursor: pointer
	font-size: 1.125rem
	height: 2rem
	width: 2rem
	border-radius: 50%
	background-color: white
	color: #b6024b
	border: solid 0.5px transparent
	&:hover
		color: white
		background-color: transparent
		border: solid 0.5px white
.item-center
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	&.fa-sign-out
		transform: translate(-45%, -50%)

.mat-mdc-row.in-webshop-bg
	background-color: #f3f3d2

.self-collect
	color: #1b00fe


ali-tooltip
	&.no-buffer
		display: block

	&.has-buffer
		display: none
