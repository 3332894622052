// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$aliacura-frontend-primary: mat.define-palette(mat.$indigo-palette);
$aliacura-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$aliacura-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$aliacura-frontend-theme: mat.define-light-theme((
	color: (
		primary: $aliacura-frontend-primary,
		accent: $aliacura-frontend-accent,
		warn: $aliacura-frontend-warn,
	),
	typography: mat.define-typography-config(
		$font-family: 'Poppins',
	),
	density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($aliacura-frontend-theme);

@import "poppins-font";

html, body {
	height: 100%;
}

body {
	margin: 0;
	font-family: Poppins, sans-serif;
	color: var(--color-background);
}

.theme-weed {
	.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label, .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
		@apply text-weed-text
	}
	.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
		@apply text-weed-text
	}
}

.mat-mdc-dialog-container .mdc-dialog__surface {
	overflow: hidden !important;
}
.mat-step-icon {
	color: var(--color-text-secondary) !important;
	background-color: var(--color-button-tertiary) !important;
}

.mat-step-icon-selected {
	color: var(--color-text) !important;
	background-color: var(--color-button-primary) !important;
}

.mat-stepper-horizontal-line {
	border-top-color: var(--color-button-tertiary) !important;
}

.mat-mdc-table {
	background-color: var(--color-card-background) !important;
}
.mat-mdc-paginator {
	background-color: var(--color-card-background) !important;
}

.mat-mdc-row {
	color: var(--color-text) !important;
}
.mat-mdc-header-row {
	color: var(--color-text) !important;
}
.mat-mdc-paginator {
	color: var(--color-text) !important;
}
.mat-mdc-select-arrow {
	color: var(--color-text) !important;
}
.mat-mdc-select {
	color: var(--color-text) !important;
}

.mat-mdc-paginator-icon {
	fill: var(--color-text) !important;
}
.mat-mdc-icon-button[disabled], .mat-mdc-icon-button.mat-mdc-button-disabled .mat-mdc-paginator-icon {
	fill: var(--color-card-background) !important;
}
.mdc-form-field {
	color: var(--color-text) !important;
}

.mat-mdc-option.mdc-list-item {
	background: var(--color-card-background) !important;
}

.mat-mdc-option {
	color: var(--color-text) !important;
}

div.mat-mdc-select-panel {
	background: var(--color-card-background) !important;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
	background: var(--color-card-background) !important;
}

.mat-step-label.mat-step-label-active {
	color: var(--color-text) !important;
}

.mat-step-label {
	color: var(--color-text) !important;
}

.mat-step-icon-state-edit {
	background-color: var(--color-button-secondary) !important;
	color: var(--color-text-secondary) !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
	color: var(--color-button-primary) !important;
}

.mat-mdc-tab .mdc-tab__text-label {
	color: var(--color-text) !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
	border-color: var(--color-button-primary) !important;
}

.mat-mdc-menu-panel {
	background: var(--color-card-background) !important;
}
