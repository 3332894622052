/** Buttons */
$createButton: #bbc120
$editButton: #fccd05
$deleteButton: #b6024b
$backButton: #343a40
$hoverLightenPercentage: 10

$primaryColor: #059033
$secondaryColor: #93CB56
$tertiaryColor: #7BAA47
$darkColor: #355A20
