.progressbar
	counter-reset: step
/** Buttons */
$createButton: #bbc120


.progressbar
	li
		list-style-type: none
		width: 20%
		float: left
		font-size: 16px
		font-weight: bold
		position: relative
		text-align: center
		text-transform: uppercase

		&:before
			width: 30px
			height: 30px
			content: counter(step)
			counter-increment: step
			line-height: 27px
			border: 3px solid #7d7d7d
			display: block
			text-align: center
			margin: 0 auto 10px auto
			border-radius: 50%
			background-color: white

		&:after
			width: 100%
			height: 2px
			content: ''
			position: absolute
			background-color: #7d7d7d
			top: 15px
			left: -50%
			z-index: -1

		&:first-child:after
			content: none

		&.active
			color: $createButton

			&:before
				border-color: $createButton

			&:after
				background-color: $createButton

	&.prescription
		li
			width: 33.33%
